import api from '../../services/api';
import { authenticate, isAuthenticated } from '../../services/auth';
import endPoints from '../../services/endPoints';
import { types } from '../types/types';
import { showToast } from './toastAction';

export const onLogin = (payload, navigate, setLoading, setIsOtpSent, setData) => async (dispatch) => {
    setLoading(true);
    payload = { email: payload.email, password: payload.password };
    const res = await api('post', endPoints.LOGIN, payload);
    if (res.success) {
        if (res.data?.two_factor_auth) {
            setIsOtpSent(true);
            setData((prev) => ({ ...prev, refreshToken: res.data.token }));
        } else {
            dispatch({ type: types.PROFILE, payload: res.data });
            authenticate(res.data?.token?.access, false, () => {
                if (isAuthenticated()) {
                    navigate('/dashboard');
                } else {
                    navigate('/login');
                }
            });
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};

export const onLoginVerify = (data, navigate, setLoading) => async (dispatch) => {
    setLoading(true);
    data = { otp_code: data?.otpCode, token: data?.refreshToken };
    const res = await api('post', endPoints.LOGIN_TWO_AUTH, data);
    if (res.success) {
        dispatch({ type: types.PROFILE, payload: res.data });
        authenticate(res.data.token.access, false, () => {
            if (isAuthenticated()) {
                navigate('/dashboard');
            } else {
                navigate('/login');
            }
        });
        // dispatch(showToast({ severity: 'success', summary: res.message }));
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};

export const onGetOtpverify = (data, setLoading, next) => async (dispatch) => {
    setLoading(true);
    data = { email: data.email, purpose: data.purpose };
    const res = await api('post', endPoints.GET_OTP, data);
    if (res.success) {
        next();
    } else {
        // dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};
export const onSignUp = (data, setLoading, next) => async (dispatch) => {
    setLoading(true);
    data = {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        password: data.password,
        otp_code: data.otpCode,
    };
    const res = await api('post', endPoints.SIGNUP, data);
    if (res.success) {
        next(res);
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};

export const getProfile = () => async (dispatch) => {
    const res = await api('get', endPoints.PROFILE);
    if (res.success) {
        dispatch({ type: types.PROFILE, payload: res.data });
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
};
export const onTwoFactorAuthChange = (data, setLoading, next) => async (dispatch) => {
    setLoading(true);
    data = { otp_code: data?.otpCode };
    const res = await api('post', endPoints.TWO_FACTOR_AUTH, data);
    if (res.success) {
        next();
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};
