import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import Error403 from './jsx/pages/Error403';
import Error404 from './jsx/pages/Error404';
import Login from './jsx/pages/Login';
import Registration from './jsx/pages/Registration';
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './css/style.css';
import LockScreen from './jsx/pages/LockScreen';
import Index from './jsx';

function App() {
    return (
        <div className="App">
            <HashRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/login" replace />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/sign-in" element={<Registration />} />
                    <Route path="/otp-verification" element={<LockScreen />} />

                    {/* /* <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/password-changed" element={<PasswordChanged />} /> */}
                    <Route path="/page-error-404" element={<Error403 />} />
                    <Route path="/page-error-403" element={<Error404 />} />
                </Routes>
                <Index />
            </HashRouter>
        </div>
    );
}

export default App;
