import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import logo from '../../images/logo/logo1.png';
import { CustomBackgroundLayout } from '../../shared/Cards/CustomCards';
import { CustomInput } from '../../shared/Inputs/CustomInput';
import formValidation from '../../utils/validations';
import PrimaryButton from '../../shared/Button/CustomButton';
import { showFormErrors } from '../../utils/commonFunctions';
import { onGetOtpverify, onSignUp } from '../../store1/actions/userActions';
import CustomCheckbox from '../../shared/Inputs/CustomCheckbox';
import { types } from '../../store1/types/types';
import { authenticate, isAuthenticated } from '../../services/auth';

function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const [loading, setLoading] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [data, setData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        otpCode: '',
        agree: false,
        purpose: 'EMAIL_VERIFICATION',
    });

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSubmit = () => {
        if (showFormErrors(data, setData, ['otpCode'])) {
            dispatch(onGetOtpverify(data, setLoading, () => setIsOtpSent(true)));
        }
    };

    const handleSignUpSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(
                onSignUp(data, setLoading, (res) => {
                    setIsOtpSent(false);
                    dispatch({ type: types.PROFILE, payload: res.data.user });
                    authenticate(res.data.token.access, false, () => {
                        if (isAuthenticated()) {
                            navigate('/dashboard');
                        } else {
                            navigate('/login');
                        }
                    });
                }),
            );
        }
    };

    console.log('data>>', data);

    const SignUpForm = () => {
        return (
            <div className=" py-2">
                <h3 className="form-title">Sign Up</h3>
                <div className="dz-separator-outer m-b5">
                    <div className="dz-separator bg-primary style-liner"></div>
                </div>
                <p>Enter your personal details below: </p>
                <CustomInput name="firstName" data={data} onChange={handleChange} />
                <CustomInput name="lastName" data={data} onChange={handleChange} />
                <CustomInput name="email" type="email" data={data} onChange={handleChange} />
                <CustomInput name="password" type="password" data={data} onChange={handleChange} />
                <CustomInput name="confirmPassword" type="password" data={data} onChange={handleChange} />
                <div className="mb-3 mt-3">
                    {/* <span className="form-check float-start me-2">
                        <input type="checkbox" className="form-check-input mt-0" id="check2" name="example1" />
                        <label className="form-check-label d-unset" htmlFor="check2">
                            I agree to the
                        </label>
                    </span> */}
                    <CustomCheckbox
                        label=" I agree to the Terms of Serive Privacy Policy"
                        data={data}
                        name="agree"
                        onChange={handleChange}
                    />
                    {/* <CustomCheckbox name="Remember me" id={'check2'} /> */}
                    {/* <label className='mt-1'>
                        <Link to={'#'}>Terms of Service </Link>&amp; <Link to={'#'}>Privacy Policy</Link>
                    </label> */}
                </div>
                <div className="form-group clearfix text-left">
                    <NavLink to="/login" className="btn btn-primary outline gray" type="button">
                        Back
                    </NavLink>
                    <PrimaryButton disabled={!data?.agree} className=" float-end" onClick={handleSubmit}>
                        Submit
                    </PrimaryButton>
                </div>
            </div>
        );
    };

    const OtpVerifyForm = () => {
        return (
            <div className=" py-2">
                <h3 className="form-title">Sign Up</h3>
                <div className="dz-separator-outer m-b5">
                    <div className="dz-separator bg-primary style-liner"></div>
                </div>
                <p>
                    For added security, you'll need to verify your identity. We've sent a verification code to{' '}
                    {data?.email}
                </p>
                <CustomInput name="otpCode" label="Verification Code" data={data} onChange={handleChange} />
                <div className="form-group clearfix text-left mt-3">
                    <PrimaryButton className=" " onClick={handleSignUpSubmit}>
                        Verify
                    </PrimaryButton>
                </div>
            </div>
        );
    };
    return (
        <CustomBackgroundLayout>
            <div className="row gx-0">
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white">
                    <div
                        id=""
                        className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                        style={{ maxHeight: '653px' }}
                    >
                        <div id="mCSB_1_container" className="mCSB_container">
                            <div className="login-form style-2">
                                <div className="card-body">
                                    <div className="logo-header">
                                        <Link to="/login" className="logo">
                                            <img src={logo} alt="" className="width-230 mCS_img_loaded" />
                                        </Link>
                                    </div>
                                    <div className=" border-bottom-0">
                                        <div className=" w-100" id="nav-tabContent">
                                            {isOtpSent ? OtpVerifyForm() : SignUpForm()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomBackgroundLayout>
    );
}

export default Register;
