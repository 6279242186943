import React, { useContext, useEffect, useReducer, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, NavLink } from 'react-router-dom';
import { MenuList } from './Menu';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { ThemeContext } from '../../../context/ThemeContext';

const reducer = (previousState, updatedState) => ({
    ...previousState,
    ...updatedState,
});

const initialState = {
    active: '',
    activeSubmenu: '',
};

const SideBar = () => {
    const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);

    const [state, setState] = useReducer(reducer, initialState);
    useEffect(() => {}, []);

    const [hideOnScroll, setHideOnScroll] = useState(true);
    useScrollPosition(
        ({ prevPos, currPos }) => {
            const isShow = currPos.y > prevPos.y;
            if (isShow !== hideOnScroll) setHideOnScroll(isShow);
        },
        [hideOnScroll],
    );

    const handleMenuActive = (status) => {
        setState({ active: status });

        if (state.active === status) {
            setState({ active: '' });
        }
    };

    return (
        <div
            className={`deznav  border-right ${iconHover} ${
                sidebarposition.value === 'fixed' &&
                sidebarLayout.value === 'horizontal' &&
                headerposition.value === 'static'
                    ? hideOnScroll > 120
                        ? 'fixed'
                        : ''
                    : ''
            }`}
        >
            <PerfectScrollbar className="deznav-scroll">
                <ul className="metismenu" id="menu">
                    {MenuList.map((data, index) => {
                        let menuClass = data.classsChange;
                        if (menuClass === 'menu-title') {
                            return (
                                <li className={menuClass} key={index}>
                                    {data.title}
                                </li>
                            );
                        } else {
                            return (
                                <li className={` ${state.active === data.title ? 'mm-active' : ''}`} key={index}>
                                    {data.content && data.content.length > 0 ? (
                                        <Link
                                            to={'#'}
                                            className=""
                                            onClick={() => {
                                                handleMenuActive(data.title);
                                            }}
                                        >
                                            {data.iconStyle}
                                            {data.iconSVG}
                                            <span className="nav-text">{data.title}</span>
                                        </Link>
                                    ) : (
                                        <NavLink
                                            to={data.to}
                                            onClick={() => {
                                                handleMenuActive(data.title);
                                            }}
                                        >
                                            {data.iconStyle}
                                            {data.iconSVG}
                                            <span className="nav-text">{data.title}</span>
                                        </NavLink>
                                    )}
                                </li>
                            );
                        }
                    })}
                </ul>
            </PerfectScrollbar>
        </div>
    );
};

export default SideBar;
