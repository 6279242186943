import { ReactComponent as CandlestickChart } from '../../../images/sidebar-icons/CandlestickChart.svg';
import { ReactComponent as AccountValidation } from '../../../images/sidebar-icons/AccountValidation.svg';
import { ReactComponent as AutoTrading } from '../../../images/sidebar-icons/AutoTrading.svg';
import { ReactComponent as BackTesting } from '../../../images/sidebar-icons/BackTesting.svg';
import { ReactComponent as CustomerSupport } from '../../../images/sidebar-icons/CustomerSupport.svg';
import { ReactComponent as PLManager } from '../../../images/sidebar-icons/PLManager.svg';
import { ReactComponent as PerformanceAnalysis } from '../../../images/sidebar-icons/PerformanceAnalysis.svg';
import { ReactComponent as ScenarioAnalysis } from '../../../images/sidebar-icons/ScenarioAnalysis.svg';
import { ReactComponent as Strategies } from '../../../images/sidebar-icons/Strategies.svg';
import { ReactComponent as TradingHistory } from '../../../images/sidebar-icons/TradingHistory.svg';

export const MenuList = [
    //Dashboard
    {
        title: 'Home',
        iconSVG: <CandlestickChart className="icon-svg" />,
        to: 'dashboard',
    },
    {
        title: 'Back Testing',
        iconSVG: <AccountValidation className="icon-svg" />,
        to: 'back-testing',
    },
    {
        title: 'Risk/Compliance',
        iconSVG: <TradingHistory className="icon-svg" />,
        to: 'risk-compliance',
    },
    {
        title: 'Market Data Analysis',
        iconSVG: <PLManager className="icon-svg" />,
        to: 'market-data-analysis',
    },
    {
        title: 'Trade Simulation',
        iconSVG: <PLManager className="icon-svg" />,
        to: 'trade-simulation',
    },
    {
        title: 'Trade Archiving',
        iconSVG: <PerformanceAnalysis className="icon-svg" />,
        to: 'trade-archiving',
    },
    {
        title: 'Trade Confirmation',
        iconSVG: <BackTesting className="icon-svg" />,
        to: 'trade-confirmation',
    },
    {
        title: 'Trade Clearing',
        iconSVG: <Strategies className="icon-svg" />,
        to: 'trade-cleaning',
    },
    {
        title: 'Settlement',
        iconSVG: <ScenarioAnalysis className="icon-svg" />,
        to: 'settlement',
    },
    {
        title: 'Trade Enrichment',
        iconSVG: <AutoTrading className="icon-svg" />,
        to: 'trade-enrichment',
    },
    {
        title: 'Compliance Reporting',
        iconSVG: <CustomerSupport className="icon-svg" />,
        to: 'compliance-reporting',
    },
    {
        title: 'Regulatory Reporting',
        iconSVG: <CustomerSupport className="icon-svg" />,
        to: 'regulatory-reporting',
    },
    {
        title: 'Customer Support',
        iconSVG: <CustomerSupport className="icon-svg" />,
        to: 'customer-support',
    },
];
