import React from 'react';

export default function PrimaryButton({ className, icon, ...props }) {
    return <button className={`btn btn-primary  ${className}`} {...props} />;
}

export function LightButton({ className, icon, outlined, ...props }) {
    return <button icon={icon} outlined className={` my-2 btn-lightblue  ${className}`} {...props} />;
}
export function CustomButton({ className, icon, outlined = true, ...props }) {
    return <button icon={icon} outlined={outlined} className={` my-2  ${className}`} {...props} />;
}

export function CustomButtonGroup({ col = '12', position = 'end', children, ...props }) {
    return (
        <div className={`col-12 md:col-${col}`}>
            <div className={`flex justify-content-${position}`}>{children}</div>
        </div>
    );
}

export function CustomSocialButton({ name }) {
    return (
        <>
            <div className="dz-social">
                <h5 className="form-title fs-20">{name}</h5>
                <ul className="dz-social-icon dz-border dz-social-icon-lg text-white">
                    <li>
                        <a
                            target="_blank"
                            href="https://www.google.com/"
                            className="fab fa-google-plus-g btn-google-plus"
                        ></a>
                    </li>
                </ul>
            </div>
        </>
    );
}
