'use client';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CustomCard } from '../shared/Cards/CustomCards';
import ChartPie from '../jsx/components/charts/Chartjs/pie';
import BarChart5 from '../jsx/components/charts/Chartjs/bar5';

const MarketDataAnalysis = () => {
    return (
        <Row>
            <Col md={6}>
                <CustomCard title="Overall Market Sentiment">{/* <ApexRedialBar /> */}</CustomCard>
            </Col>
            <Col md={6}>
                <CustomCard title="Risk Management">
                    <ChartPie height={500} />
                </CustomCard>
            </Col>
            <Col md={12}>
                <CustomCard title="Liquidity Risk Reporting">
                    <BarChart5 />
                </CustomCard>
            </Col>
        </Row>
    );
};

export default MarketDataAnalysis;
