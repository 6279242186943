const endPoints = {
    LOGIN: '/login/',
    FORGOT_PASSWORD: '/forgot-password',
    PROFILE: '/profile/',
    GET_OTP: '/send-otp/',
    SIGNUP: '/signup/',
    LOGIN_TWO_AUTH: '/two-factor-verify/',
    TWO_FACTOR_AUTH: '/two-factor-auth/',
};
export default endPoints;
