import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo/logo1.png';
import { CustomBackgroundLayout } from '../../shared/Cards/CustomCards';
import { CustomInput } from '../../shared/Inputs/CustomInput';
import PrimaryButton, { CustomSocialButton } from '../../shared/Button/CustomButton';
import CustomCheckbox from '../../shared/Inputs/CustomCheckbox';
import formValidation from '../../utils/validations';
import { showFormErrors } from '../../utils/commonFunctions';
import { onLogin, onLoginVerify } from '../../store1/actions/userActions';
import { useDispatch } from 'react-redux';
import { isAuthenticated } from '../../services/auth';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/dashboard');
        }
    }, [navigate]);
   
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        email: "",
        password: "",
        otpCode: "", refreshToken: ""
    });
    const [isOtpSent, setIsOtpSent] = useState(false);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data)
        setData((prev) => ({ ...prev, [name]: value, formErrors }))
    }

    const handleLogin = () => {
        if (showFormErrors(data, setData, ["otpCode"])) {
            dispatch(onLogin(data, navigate, setLoading, setIsOtpSent, setData))
        }
    }

    const handleLoginSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(onLoginVerify(data, navigate, setLoading))
        }
    }

    const LoginForm = () => {
        return (
            <div className="pb-3">
                <h3 className="form-title m-t0">Personal Information</h3>
                <div className="dz-separator-outer m-b5">
                    <div className="dz-separator bg-primary style-liner"></div>
                </div>
                <p>Enter your e-mail address and your password. </p>
                <CustomInput name="email" type="email" data={data} onChange={handleChange} />
                <CustomInput name="password" type="password" data={data} onChange={handleChange} />
                <PrimaryButton className=" mt-3" onClick={handleLogin}>Login</PrimaryButton>
                <div className=" d-flex flex-row mb-3 ">
                    <CustomCheckbox label="Remember me" />
                    <div className="d-flex nav-link text-end mt-2">ForgotPassword</div>
                </div>
                <CustomSocialButton name="Sign In With" />
                <div className="text-center bottom">
                    <PrimaryButton className=" button-md btn-block mt-3" onClick={() => navigate('/sign-in')}>
                        Create an account
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const OtpVerifyForm = () => {
        return (
            <div className=" py-2">
                <h3 className="form-title">Sign Up</h3>
                <div className="dz-separator-outer m-b5">
                    <div className="dz-separator bg-primary style-liner"></div>
                </div>
                <p>For added security, you'll need to verify your identity. We've sent a verification code to {data?.email}
                </p>
                <CustomInput name="otpCode" label="Verification Code" data={data} onChange={handleChange} />
                <div className="form-group clearfix text-left mt-3">
                    <PrimaryButton className=" " onClick={handleLoginSubmit}>
                        Verify
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    return (
        <CustomBackgroundLayout>
            <div className="row gx-0">
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
                    <div id="mCSB_1" className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" style={{ maxHeight: '653px' }}>
                        <div className="login-form style-2">
                            <div className="card-body">
                                <div className="logo-header">
                                    <Link to={'#'} className="logo">
                                        <img src={logo} alt="" className="width-230 mCS_img_loaded" />
                                    </Link>
                                </div>
                                <div className="border-bottom-0">
                                    <div className="w-100" id="nav-tabContent">
                                        {isOtpSent ? OtpVerifyForm() : LoginForm()}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomBackgroundLayout>
    );
};

export default Login;
