import AppProfile from '../jsx/components/AppsMenu/AppProfile/AppProfile';
import EditProfile from '../jsx/components/AppsMenu/AppProfile/EditProfile';
import BackTesting from '../views/BackTesting';
import ComplainceReporting from '../views/ComplainceReporting';
import CustomerSupport from '../views/CustomerSupport';
import Dashboard from '../views/Dashboard/Dashboard';
import MarketDataAnalysis from '../views/MarketDataAnalysis';
import RegulatoryReporting from '../views/RegulatoryReporting';
import Risk from '../views/Risk';
import RunTesting from '../views/RunTesting';
import Settlement from '../views/Settlement';
import TradeArchiving from '../views/TradeArchiving';
import TradeCleaning from '../views/TradeCleaning';
import TradeConfirmation from '../views/TradeConfirmation';
import TradeEnrichment from '../views/TradeEnrichment';
import TradeSimulation from '../views/TradeSimulation';

export const PrivateRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        element: <Dashboard />,
    },
    {
        path: '/profile',
        name: 'Profile Settings',
        element: <AppProfile />,
    },
    {
        path: '/edit-profile',
        name: 'Edit Profile',
        element: <EditProfile />,
    },
    {
        path: '/back-testing',
        name: 'Dashboard',
        element: <BackTesting />,
    },
    {
        path: '/run-testing',
        name: 'Run Testing',
        element: <RunTesting />,
    },
    {
        path: '/risk-compliance',
        name: 'Risk Compliance',
        element: <Risk />,
    },
    {
        path: '/market-data-analysis',
        name: 'Dashboard',
        element: <MarketDataAnalysis />,
    },
    {
        path: '/trade-simulation',
        name: 'Dashboard',
        element: <TradeSimulation />,
    },
    {
        path: '/trade-archiving',
        name: 'Dashboard',
        element: <TradeArchiving />,
    },
    {
        path: '/trade-confirmation',
        name: 'Dashboard',
        element: <TradeConfirmation />,
    },
    {
        path: '/trade-cleaning',
        name: 'Dashboard',
        element: <TradeCleaning />,
    },
    {
        path: '/settlement',
        name: 'Dashboard',
        element: <Settlement />,
    },
    {
        path: '/trade-enrichment',
        name: 'Dashboard',
        element: <TradeEnrichment />,
    },
    {
        path: '/compliance-reporting',
        name: 'Dashboard',
        element: <ComplainceReporting />,
    },
    {
        path: '/regulatory-reporting',
        name: 'Dashboard',
        element: <RegulatoryReporting />,
    },
    {
        path: '/customer-support',
        name: 'Dashboard',
        element: <CustomerSupport />,
    },
];
